import React from 'react';
import { Container, ContainerProps } from '@mui/material';

import { mergeSx } from '~/utils/mui';

const FrontContainer = ({ component, sx, ...props }: ContainerProps & { component?: React.ElementType }) => {
    return (
        <Container
            component={component || 'div'}
            sx={mergeSx(
                {
                    px: { xs: 6, sm: 8, md: 12 },
                    display: 'flex',
                    flexDirection: 'column',
                },
                sx,
            )}
            {...props}
        />
    );
};

export default FrontContainer;
